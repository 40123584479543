/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const ticketsStore = {
  namespaced: true,
  state: {
    groups: [], // groupsCount??
    ticketActionsTotal: 0,
    ticketActionsList: [],

    ticketAgentsTotal: 0,
    ticketAgentsList: [],

    ticketGroupsTotal: 0,
    ticketGroupsList: [],

    ticketObjectsTypesTotal: 0,
    ticketObjectsTypesList: [],

    ticketPrioritiesTotal: 0,
    ticketPrioritiesList: [],

    ticketStatesTotal: 0,
    ticketStatesList: [],

    ticketStatesMapTotal: 0,
    ticketStatesMapList: [],
  },

  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = payload.data;
    },
    SET_TICKET_ACTIONS(state, payload) {
      state.ticketActionsTotal = payload.total;
      state.ticketActionsList = payload.data;
    },
    SET_TICKET_AGENTS(state, payload) {
      state.ticketAgentsTotal = payload.total;
      state.ticketAgentsList = payload.data;
    },
    SET_TICKET_GROUPS(state, payload) {
      state.ticketGroupsTotal = payload.total;
      state.ticketGroupsList = payload.data;
    },
    SET_TICKET_OBJECTS_TYPES(state, payload) {
      state.ticketObjectsTypesTotal = payload.total;
      state.ticketObjectsTypesList = payload.data;
    },
    SET_TICKET_PRIORITIES(state, payload) {
      state.ticketPrioritiesTotal = payload.total;
      state.ticketPrioritiesList = payload.data;
    },
    SET_TICKET_STATES(state, payload) {
      state.ticketStatesTotal = payload.total;
      state.ticketStatesList = payload.data;
    },
    SET_TICKET_STATES_MAP(state, payload) {
      state.ticketStatesMapTotal = payload.total;
      state.ticketStatesMapList = payload.data;
    },
  },
  actions: {
    async getTicketsActions(context, payload) {
      const response = await req.post('ticketActionsGetList', payload);
      context.commit('SET_TICKET_ACTIONS', response.data);
    },
    async getGroups({ commit }, payload) {
      try {
        const response = await req.post('ticketGroups', payload);
        commit('SET_GROUPS', response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async removeTicketAction(context, payload) {
      const response = await req.post('ticketActionsRemove', payload);
      return response;
    },
    async addNewTicketAction(context, payload) {
      const response = await req.post('ticketActionsCreate', payload);
      return response;
    },
    // ticket Agents
    async getTicketAgents(context, payload) {
      const response = await req.post('ticketAgentsGetList', payload);
      context.commit('SET_TICKET_AGENTS', response.data);
    },
    async removeTicketAgent(context, payload) {
      const response = await req.post('ticketAgentsDelete', payload);
      return response;
    },
    async updateTicketAgent(context, payload) {
      const response = await req.post('ticketAgentsUpdate', payload);
      return response;
    },
    async addNewTicketAgent(context, payload) {
      const response = await req.post('ticketAgentsSave', payload);
      return response;
    },
    // ticket Groups
    async getTicketGroups(context, payload) {
      const response = await req.post('ticketGroupsGetList', payload);
      context.commit('SET_TICKET_GROUPS', response.data);
      return response;
    },
    async removeTicketGroup(context, payload) {
      const response = await req.post('ticketGroupsDelete', payload);
      return response;
    },
    async updateTicketGroup(context, payload) {
      const response = await req.post('ticketGroupsUpdate', payload);
      return response;
    },
    async addNewTicketGroup(context, payload) {
      const response = await req.post('ticketGroupsCreate', payload);
      return response;
    },

    // ticket Objects Types
    async getTicketObjectsTypes(context, payload) {
      const response = await req.post('ticketObjectsTypesGetList', payload);
      context.commit('SET_TICKET_OBJECTS_TYPES', response.data);
      return response;
    },
    async removeTicketObjectsTypes(context, payload) {
      const response = await req.post('ticketObjectsTypesDelete', payload);
      return response;
    },
    async updateTicketObjectsTypes(context, payload) {
      const response = await req.post('ticketObjectsTypesUpdate', payload);
      return response;
    },
    async addTicketObjectsTypes(context, payload) {
      const response = await req.post('ticketObjectsTypesCreate', payload);
      return response;
    },

    // tickets Priorities
    async getTicketPriorities(context, payload) {
      const response = await req.post('ticketPrioritiesGetList', payload);
      context.commit('SET_TICKET_PRIORITIES', response.data);
      return response;
    },
    async removeTicketPriorities(context, payload) {
      const response = await req.post('ticketPrioritiesDelete', payload);
      return response;
    },
    async updateTicketPriorities(context, payload) {
      const response = await req.post('ticketPrioritiesUpdate', payload);
      return response;
    },
    async addTicketPriorities(context, payload) {
      const response = await req.post('ticketPrioritiesCreate', payload);
      return response;
    },

    // ticket States
    async getTicketStates(context, payload) {
      const response = await req.post('ticketStatesGetList', payload);
      context.commit('SET_TICKET_STATES', response.data);
      return response;
    },
    async removeTicketStates(context, payload) {
      const response = await req.post('ticketStatesDelete', payload);
      return response;
    },
    async updateTicketStates(context, payload) {
      const response = await req.post('ticketStatesUpdate', payload);
      return response;
    },
    async addTicketStates(context, payload) {
      const response = await req.post('ticketStatesCreate', payload);
      return response;
    },

    // ticket states map
    async getTicketStatesMap(context, payload) {
      const response = await req.post('ticketStatesMapGetList', payload);
      context.commit('SET_TICKET_STATES_MAP', response.data);
      return response;
    },
    async removeTicketStatesMap(context, payload) {
      const response = await req.post('ticketStatesMapDelete', payload);
      return response;
    },
    async updateTicketStatesMap(context, payload) {
      const response = await req.post('ticketStatesMapUpdate', payload);
      return response;
    },
    async addTicketStatesMap(context, payload) {
      const response = await req.post('ticketStatesMapCreate', payload);
      return response;
    },
  },
};

export default ticketsStore;
