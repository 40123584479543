/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const meteringPointsStore = {
  namespaced: true,
  state: {
    meteringPointsList: [],
    meteringPointsTypeList: [
      { mp_mrt_code: 1, mrt_name: 'Холодная вода' },
      { mp_mrt_code: 2, mrt_name: 'Горячая вода' },
      { mp_mrt_code: 3, mrt_name: 'Электроэнергия' },
      { mp_mrt_code: 4, mrt_name: 'Отопление' },
    ],
    totalMeteringPoints: 0,
  },

  mutations: {
    ADD_METERING_POINTS(state, payload) {
      state.meteringPointsList.push(payload);
    },
    SET_METERING_POINTS(state, payload) {
      state.meteringPointsList = payload.data;
      state.totalMeteringPoints = payload.total;
    },
    UPDATE_METERING_POINTS(state, payload) {
      state.meteringPointsList = state.meteringPointsList.map((meteringPoint) => {
        if (meteringPoint.mp_id === payload.mp_id) {
          return payload;
        }
        return meteringPoint;
      });
    },
    DELETE_METERING_POINTS(state, payload) {
      state.meteringPointsList = state.meteringPointsList.filter(
        // eslint-disable-next-line camelcase
        ({ mp_id }) => mp_id !== payload,
      );
    },
  },
  actions: {
    async addNewMeteringPoint(context, payload) {
      await req.post('meteringPointsSave', {
        obj: { ...payload },
      });
    },
    async updateMeteringPoint(context, payload) {
      await req.post('meteringPointsSave', {
        obj: { ...payload },
      });
    },
    async getMeteringPoints(context, payload) {
      const { data } = await req.post('meteringPoints', payload);
      context.commit('SET_METERING_POINTS', data);
    },
    async deleteMeteringPoint(context, payload) {
      context.commit('DELETE_METERING_POINTS', payload);
    },
  },
};

export default meteringPointsStore;
