/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const skudPremisesStore = {
  namespaced: true,
  state: {
    skudPremises: [],
    skudPremisesCount: 0,
  },
  mutations: {
    SET_PREMISES: (state, payload) => {
      state.skudPremises = payload.data;
      state.skudPremisesCount = payload.total;
    },
  },
  actions: {
    async getSkudPremises(context, payload) {
      const response = await req.post('skudPremises', payload);
      context.commit('SET_PREMISES', response.data);
    },
  },
};

export default skudPremisesStore;
