// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
// import req from '@/plugins/requests';

const websocketStore = {
  namespaced: true,

  state: {
    socket: null,
    connectionStatus: false,
    reconnectTimeout: 10000,
    meteringDeviceData: [],
    meteringDeviceParams: '',
  },

  mutations: {
    OPEN_SOCKET(state, socket) {
      state.socket = socket;
    },

    CLOSE_SOCKET(state) {
      if (state.socket) {
        state.socket.close();
      }
      state.socket = null;
    },

    SET_CONNECTION_STATUS(state, value) {
      state.connectionStatus = value;
    },

    SET_METERING_DEVICE_DATA(state, payload) {
      state.meteringDeviceData = payload;
    },

    SET_METERING_DEVICE_CHART(state, { dataType, chart }) {
      state.meteringDeviceData
        .find((item) => item.dataType === dataType)?.chart.shift(); // тут был cosem
      state.meteringDeviceData
        .find((item) => item.dataType === dataType)?.chart.push(chart); // и тут был
    },

    RESET_METERING_DEVICE_DATA(state) {
      state.meteringDeviceData = [];
    },

    SET_METERING_DEVICE_PARAMS(state, payload) {
      state.meteringDeviceParams = payload;
    },
    RESET_METERING_DEVICE_PARAMS(state) {
      state.meteringDeviceParams = {};
    },
  },

  actions: {
    async connectSocket({ commit, state, dispatch }, { url, isReconnect = false }) {
      const { login, token, session } = store.getters['authStore/user'];
      const authUrl = `${url}/${login}/${session}/${token}`;
      const socket = new WebSocket(authUrl);

      socket.onopen = () => {
        if (!isReconnect) {
          commit('SET_CONNECTION_STATUS', true);
        }

        commit('OPEN_SOCKET', socket);
      };

      socket.onmessage = ({ data }) => {
        const message = JSON.parse(data);

        switch (message.cmd) {
          case 'login': {
            console.log('Socket is opened and ready to interact.');
            break;
          }
          case 'msg': {
            if (Object.hasOwn(message.dat, 'text')) {
              store.commit('notificationMessages', {
                message: message.dat.text,
                type: 'success',
              });
            }
            break;
          }
          case 'meter': {
            // cri0 - check object has property
            if (!Object.hasOwn(message.dat, 'value')) {
              message.dat.value = 0.0;
            }

            const date = new Date();
            const time = new Intl.DateTimeFormat('ru', {
              timeStyle: 'medium',
            }).format(date);

            const payload = {
              dataType: message.dat.data_type,
              chart: {
                x: `${time}`,
                y: message.dat.value,
              },
            };

            commit('SET_METERING_DEVICE_CHART', payload);
            break;
          }
          default: {
            console.log('unknown type of message: ', message.cmd);
          }
        }
      };

      socket.onclose = () => {
        if (state.connectionStatus) {
          setTimeout(() => {
            dispatch('connectSocket', { url, isReconnect: true });
          }, state.reconnectTimeout);
        }
      };
    },

    disconnectSocket({ commit }) {
      commit('SET_CONNECTION_STATUS', false);
      commit('CLOSE_SOCKET');
    },

    async subscribeMeteringDevice({ state, commit }, device) {
      commit('SET_METERING_DEVICE_PARAMS', {
        id: device.metering_point_id, // no need?
        premise: device.premise_id, // no need?
        prm: `mpoint/p${device.premise_id}/mp${device.metering_point_id}`,
      });

      // const { id } = device;

      if (state.socket) {
        console.log(`subscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        // const response = await req.post('deviceGraphData', { id });
        // console.log('device graph data: ', JSON.stringify(response.data, 0, 2));

        const response = {
          code: 20,
          data: [
            {
              serial: '6778567546363',
              type_name: 'А+ нарастающим итогом по тарифам',
              type_cosem: '1.0.1.8.0.255',
              type_color: '#000000',
              type_chart: '3',
              proto_read_multiply: 0.000001,
              proto_store_multiply: 1,
              unit_short: 'Квт*ч',
              data_type: 161,
            },
            {
              serial: '6778567546363',
              type_name: 'Напряжение U (1ф ПУ)',
              type_cosem: '1.0.12.7.0.255',
              type_color: '#2196F3',
              type_chart: '2',
              proto_read_multiply: 1,
              proto_store_multiply: 1,
              unit_short: 'В',
              data_type: 114,
            },
            {
              serial: '6778567546363',
              type_name: 'Частота сети, F',
              type_cosem: '1.0.14.7.0.255',
              type_color: '#7F56D9',
              type_chart: '2',
              proto_read_multiply: 0.01,
              proto_store_multiply: 1,
              unit_short: 'Гц',
              data_type: 121,
            },
            {
              serial: '6778567546363',
              type_name: 'Ток I (1ф ПУ)',
              type_cosem: '1.0.11.7.0.255',
              type_color: '#000000',
              type_chart: '2',
              proto_read_multiply: 1,
              proto_store_multiply: 1,
              unit_short: 'А',
              data_type: 128,
            },
            {
              serial: '6778567546363',
              type_name: 'А- нарастающим итогом по тарифам',
              type_cosem: '1.0.2.8.0.255',
              type_color: '#24A752FF',
              type_chart: '1',
              proto_read_multiply: 1,
              proto_store_multiply: 1,
              unit_short: 'Квт*ч',
              data_type: 999,
            },
            {
              serial: '6778567546363',
              type_name: 'Активная мощность суммарно по всем фазам, Pabc',
              type_cosem: '1.0.1.7.0.255',
              type_color: '#000000',
              type_chart: '1',
              proto_read_multiply: 1,
              proto_store_multiply: 1,
              unit_short: 'Нет',
              data_type: 999,
            },
          ],
        };

        if (response.code === 20) {
          const deviceGraphData = response.data.map((item) => ({
            serial: item.serial,
            title: item.type_name,
            code: `mp${device.metering_point_id}`,
            backgroundColor: item.type_color,
            chartType: item.type_chart, // no need??
            readMultiply: item.proto_read_multiply, // no need??
            storeMultiply: item.roto_store_multiply, // no need??
            label: item.unit_short,
            dataType: item.data_type, // need to add device graph data
          }));

          const defaultChart = [];
          const defaultChartData = {
            y: 0,
            x: '',
          };

          for (let i = 0; i < 30; i += 1) {
            defaultChart.push(defaultChartData);
          }

          commit(
            'SET_METERING_DEVICE_DATA',
            deviceGraphData.map((dataItem) => ({
              ...dataItem,
              chart: [...defaultChart],
            })),
          );

          state.socket.send(
            JSON.stringify({
              cmd: 'submp',
              prm: state.meteringDeviceParams.prm,
              dat: {},
            }),
          );
        }
      }
    },

    unsubscribeMeteringDevice({ state, commit }) {
      if (state.socket) {
        console.log(`unsubscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        state.socket.send(
          JSON.stringify({
            cmd: 'unsub',
            prm: state.meteringDeviceParams.prm,
            dat: {},
          }),
        );
      }

      commit('RESET_METERING_DEVICE_DATA');
      commit('RESET_METERING_DEVICE_PARAMS');
    },
  },
};

export default websocketStore;
