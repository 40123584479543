/* eslint-disable import/prefer-default-export */
export const directoriesRoutes = [
  {
    path: '/directories/units',
    name: 'Units',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Единицы измерения', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/Units/UnitsTable.vue'),
  },
  {
    path: '/directories/meters-data-types',
    name: 'MetersDataTypes',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Типы данных учета', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/MetersDataTypes/MetersDataTypesTable.vue'),
  },
  {
    path: '/directories/meters-data-map',
    name: 'MetersDataMap',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Сопоставление драйвера устройства с типами данных', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/MetersDataMap/MetersDataMap.vue'),
  },
  {
    path: '/directories/resource-types',
    name: 'ResourceTypes',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Виды ресурсов', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/MeteringResourcesTypes/ResourceTypes.vue'),
  },
  {
    path: '/directories/premises-types',
    name: 'PremisesTypes',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Типы помещений', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/PremisesTypes/PremisesTypes.vue'),
  },
  {
    path: '/directories/devices-models',
    name: 'devicesModels',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Справочники', disabled: true },
        { text: 'Модели устройств', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/DevicesModels/DevicesModels.vue'),
  },
  {
    path: '/directories/groups',
    name: 'Groups',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Права доступа', disabled: true },
        { text: 'Группы', disabled: true },
      ],
    },
    component: () => import('@/components/Directories/Groups/GroupsObjects.vue'),
  },
];
