/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const citiesStore = {
  namespaced: true,
  state: {
    totalCities: 0,
    citiesList: [],
  },

  mutations: {
    ADD_CITY(state, payload) {
      state.citiesList.push(payload);
    },
    SET_CITIES(state, payload) {
      state.citiesList = payload.data;
      state.totalCities = payload.total;
    },
    UPDATE_CITY(state, payload) {
      state.citiesList = state.citiesList.map((city) => {
        if (city.c_id === payload.c_id) {
          // eslint-disable-next-line no-param-reassign
          city = payload;
          return city;
        }
        return city;
      });
    },
    DELETE_CITY(state, payload) {
      state.citiesList = state.citiesList.filter((city) => city.c_id !== payload);
    },
  },
  actions: {
    async addNewCity(context, payload) {
      await req.post('citiesSave', { obj: { ...payload } });
    },
    async updateCity(context, payload) {
      await req.post('citiesSave', { obj: { ...payload } });
    },
    removeCity(context, payload) {
      context.commit('DELETE_CITY', payload);
    },
    async getCities(context, payload) {
      const response = await req.post('cities', payload);
      context.commit('SET_CITIES', response.data);
    },
  },
};

export default citiesStore;
