/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const premisesStore = {
  namespaced: true,
  state: {
    premisesList: [],
    premisesTypesList: [],
    totalPremises: 0,
  },

  mutations: {
    ADD_PREMISES(state, payload) {
      state.premisesList.push(payload);
    },
    SET_PREMISES(state, payload) {
      state.premisesList = payload.data;
      state.totalPremises = payload.total;
    },
    SET_PREMISES_TYPES(state, payload) {
      state.premisesTypesList = payload.data;
    },
    UPDATE_PREMISES(state, payload) {
      state.premisesList = state.premisesList.map((pr) => {
        if (pr.p_id === payload.p_id) {
          // eslint-disable-next-line no-param-reassign
          pr = payload;
          return pr;
        }
        return pr;
      });
    },
    DELETE_PREMISES(state, payload) {
      state.premisesList = state.premisesList.filter((pr) => pr.p_id !== payload);
    },
  },
  actions: {
    async addNewPremises(context, payload) {
      await req.post('premisesSave', { obj: { ...payload } });
    },
    async updatePremises(context, payload) {
      await req.post('premisesSave', { obj: { ...payload } });
    },
    async getPremises(context, payload) {
      const response = await req.post('premises', payload);
      context.commit('SET_PREMISES', response.data);
    },
    async getPremisesTypes(context, payload) {
      const response = await req.post('premisesTypes', payload);
      context.commit('SET_PREMISES_TYPES', response.data);
    },
    async deletePremises(context, payload) {
      context.commit('DELETE_PREMISES', payload);
    },
  },
};

export default premisesStore;
