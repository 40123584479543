/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const premisesTypesStore = {
  namespaced: true,
  state: {
    premisesTypesTotal: 0,
    premisesTypesList: [],
  },
  mutations: {
    SET_PREMISES_TYPES(state, payload) {
      state.premisesTypesTotal = payload.total;
      state.premisesTypesList = payload.data;
    },
  },
  actions: {
    async getPremisesTypes(context, payload) {
      const response = await req.post('premisesTypes', payload);
      context.commit('SET_PREMISES_TYPES', response.data);
    },
  },
};

export default premisesTypesStore;
