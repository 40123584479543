/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const skudDevicesStore = {
  namespaced: true,
  state: {
    skudDevices: [],
    skudDevicesCount: 0,
  },
  mutations: {
    SET_DEVICES: (state, payload) => {
      state.skudDevices = payload.data;
      state.skudDevicesCount = payload.total;
    },
  },
  actions: {
    async getSkudDevices(context, payload) {
      const response = await req.post('skudDevices', payload);
      context.commit('SET_DEVICES', response.data);
    },
  },
};

export default skudDevicesStore;
