/* eslint-disable import/no-cycle */
import store from '@/store';
import router from '@/router';
import apiCodes from './apiCodes';
import httpApi from './httpApi';

const requestReport = (responseResult) => {
  if (!responseResult) {
    return {
      msg: 'Неизвестная ошибка.',
      type: 'error',
    };
  }

  const {
    status, req, code, txt,
  } = responseResult;

  let msg = '';
  let type = '';

  switch (status) {
    case 'success':
      switch (req) {
        case 'create':
          msg = 'Запись добавлена.';
          type = 'success';
          break;
        case 'update':
          msg = 'Запись изменена.';
          type = 'success';
          break;
        case 'delete':
          msg = 'Запись удалена.';
          type = 'success';
          break;
        default:
          break;
      }
      break;
    case 'error':
      switch (req) {
        case 'create':
          msg = `Ошибка добавления записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'update':
          msg = `Ошибка изменения записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'delete':
          msg = `Ошибка удаления записи: ${code}, ${txt}`;
          type = 'error';
          break;
        case 'login':
          msg = 'Ошибка входа, проверьте логин и пароль.';
          type = 'error';
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return { msg, type };
};

const req = async (reqName, method, param) => {
  try {
    const headers = store.getters['authStore/headerAuth'];
    const response = await httpApi.post(reqName, { headers, param });

    const { data } = response;

    if (data.code === apiCodes.wrongToken || data.code === apiCodes.wrongRefreshToken) {
      store.commit('authStore/loggedOut');
      store.dispatch('websocketStore/disconnectSocket');
      router.push({ name: 'Login' });
      throw new Error(`${data.code}`, {
        cause: {
          status: 'error',
          req: 'login',
        },
      });
    }

    // ToDo: relogin on bad auth data,

    // need to update token
    if (data.code === apiCodes.tokenUpdate) {
      store.commit('authStore/UpdateToken', data.token);
      const refresh = await req(reqName, method, param);
      return refresh;
    }

    // navigate to main after successfull login
    if (data.code === apiCodes.ok && data.route === 'main') {
      return response;
    }

    // wrong passsword or login when auth
    if (data.code === apiCodes.postgreError && data.route === 'login') {
      throw new Error(`${data.code}`, {
        cause: {
          status: 'error',
          req: 'login',
          txt: data.msg,
          code: data.code,
        },
      });
    }

    // wrong data response format
    if (data.code === apiCodes.wrongFormat) {
      throw new Error(`${data.code}`, {
        cause: {
          status: 'error', req: 'create', txt: data.result.txt, code: data.code,
        },
      });
    }

    // result message of any action
    if (data.result && data.result.req !== 'list') {
      store.commit('notificationMessages', {
        ...requestReport(data.result),
      });
      return data;
    }

    return response;
  } catch (error) {
    store.commit('notificationMessages', {
      ...requestReport(error.cause),
    });
    return { code: apiCodes.unknownError };
  }
};

// eslint-disable-next-line consistent-return
const reqUploadData = async (reqName, { file, comma, comment }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('comma', comma);
  formData.append('comment', comment);

  try {
    const headers = store.getters['authStore/headerAuth'];
    const { data } = await httpApi.upload(reqName, { file, comma, comment }, headers);

    if (data.code === apiCodes.tokenUpdate) {
      store.commit('authStore/UpdateToken', data.token);
      const refresh = await httpApi.upload(reqName, { file, comma, comment }, headers);
      return refresh;
    }

    // TODO: what if backend return no data without errors?
    if (data.result.status === 'success') {
      return {
        status: 'success',
        countErrors: data.countErrors,
        countRows: data.countRows,
      };
    }
  } catch ({ response }) {
    // TODO: catch any other errors
    if (response.data && response.status !== 400) {
      return {
        status: 'error',
        countErrors: response.data.countErrors,
        countRows: response.data.countRows,
        errorsList: response.data.errors,
      };
    }
    return {
      status: 'error',
      countErrors: 0,
      countRows: 0,
      errorsList: [
        {
          row: 0,
          text: 'Проверьте параметры парсинга.',
        },
      ],
    };
  }
};

export default {
  request: (reqName, method, param) => req(reqName, method, param),
  get: (reqName, param) => req(reqName, 'get', param),
  post: (reqName, param) => req(reqName, 'post', param),
  del: (reqName, param) => req(reqName, 'delete', param),
  upload: (reqName, param) => reqUploadData(reqName, param),
};
