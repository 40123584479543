import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '../store';
import appRoutes from './menuRoutes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'Login', auth: false },
    component: () => import('../components/LoginForm.vue'),
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [{ text: 'Дашборд', disabled: true }],
    },
    component: () => import('../components/Dashboard.vue'),
  },
  // Маршруты Объектов
  ...appRoutes.objects,
  // Клиенты
  ...appRoutes.clients,
  // Устройства
  ...appRoutes.devices,
  // Тарификация
  ...appRoutes.billing,
  // Скуд (справочники)
  ...appRoutes.acsDirectories,
  // Справочники
  ...appRoutes.directories,
  // Журналы
  ...appRoutes.logs,
  // Отчеты
  ...appRoutes.reports,
  // Настройки
  ...appRoutes.settings,
  // Заявки
  ...appRoutes.tickets,
  // Навигация по умолчанию
  {
    path: '*',
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (!store.getters['authStore/isLoggedIn']) {
      if (localStorage.getItem('r1_session') !== null) {
        // eslint-disable-next-line camelcase
        const r1_ses = JSON.parse(localStorage.getItem('r1_session'));
        store.commit('authStore/loggedIn', {
          login: r1_ses.login,
          token: r1_ses.token,
          mode: r1_ses.mode,
          group: r1_ses.group,
          // eslint-disable-next-line radix
          session: parseInt(r1_ses.session),
        });
      }
    }
    if (store.getters['authStore/isLoggedIn']) {
      next();
    } else {
      // TODO Logout before send user to Login page
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

export default router;
