/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const userSettings = {
  namespaced: true,
  state: {
    activeSessionsList: [],
    userData: {},
    total: 0,
  },
  getters: {
    sessionsList: (state) => {
      if (!state.activeSessionsList) {
        return [];
      }
      return state.activeSessionsList;
    },
    userData: (state) => state.userData,
    totalSessions: (state) => state.total,
  },
  mutations: {
    FETCH_SESSIONS(state, payload) {
      if (payload.data) {
        state.activeSessionsList = payload.data.map((session) => {
          const timeCreate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.create_time) * 1000,
          ).toLocaleString('Ru-ru');
          const timeUpdate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.update_time) * 1000,
          ).toLocaleString('Ru-ru');

          return { ...session, timeCreate, timeUpdate };
        });
      }
      state.total = payload.total;
    },
    FETCH_USER_DATA(state, payload) {
      state.userData = payload.data;
    },
  },
  actions: {
    async getSessionsList(context, payload) {
      const response = await req.post('userSessions', payload);
      context.commit('FETCH_SESSIONS', response.data);
    },
    async getUserData(context) {
      const response = await req.post('userSettings');
      context.commit('FETCH_USER_DATA', response.data);
    },
    async deleteSession(context, payload) {
      await req.post('userSessionDelete', { id: payload });
    },
    async updatePassword(context, payload) {
      await req.post('userPasswordUpdate', payload);
    },
  },
};

export default userSettings;
