import axios from 'axios';
import routes from './routes';

const req = async (reqName, method, params) => {
  const { headers, param } = params;
  const response = await axios({
    responseType: 'json',
    method,
    url: `${routes.conf().apiURL}${routes.uri[reqName]}`,
    headers,
    data: { ...param },
  });

  return response;
};

const reqUploadData = async (reqName, { file, comma, comment }, headers) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('comma', comma);
  formData.append('comment', comment);

  const response = await axios({
    'Content-Type': 'multipart/form-data',
    method: 'post',
    url: `${routes.conf().apiURL}${routes.uri[reqName]}`,
    headers,
    data: formData,
  });

  return response;
};

export default {
  request: (reqName, method, param) => req(reqName, method, param),
  get: (reqName, param) => req(reqName, 'get', param),
  post: (reqName, param) => req(reqName, 'post', param),
  del: (reqName, param) => req(reqName, 'delete', param),
  upload: (reqName, param, headers) => reqUploadData(reqName, param, headers),
};
