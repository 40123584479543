/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const personsStore = {
  namespaced: true,
  state: {
    list: [],
    totalPersons: 0,
  },
  getters: {
    personsList: (state) => {
      if (!state.list) {
        return [];
      }
      return state.list.map((person) => {
        // eslint-disable-next-line no-param-reassign
        person.fullName = `${person.lname} ${person.fname} ${person.mname}`;
        return person;
      });
    },
  },
  mutations: {
    ADD_PERSON(state, payload) {
      const person = {
        ...payload,
        passwd: '',
        fullName: `${payload.lname} ${payload.fname} ${payload.mname}`,
      };

      state.list.push(person);
    },
    SET_PERSONS(state, payload) {
      state.list = payload.data;
      state.totalPersons = payload.total;
    },
    UPDATE_PERSON(state, payload) {
      state.list = state.list.map((person) => {
        if (person.pid === payload.pid) {
          // eslint-disable-next-line no-param-reassign
          person = payload;
          return person;
        }
        return person;
      });
    },
    DELETE_PERSON(state, payload) {
      state.list = state.list.filter((person) => person.pid !== payload);
    },
  },
  actions: {
    async addNewPerson(context, payload) {
      await req.post('personsSave', { obj: { ...payload } });
    },
    async updatePerson(context, payload) {
      const response = await req.post('personsSave', { obj: { ...payload } });
      return response;
    },
    removePerson(context, payload) {
      context.commit('DELETE_PERSON', payload);
    },
    async getPersons(context, payload) {
      const response = await req.post('persons', payload);
      context.commit('SET_PERSONS', response.data);
    },
  },
};

export default personsStore;
