// import req from '@/plugins/requests';
/* eslint-disable import/no-cycle */
import storage from '@/plugins/storage';

const authStore = {
  namespaced: true,
  state: {
    loggedIn: false,
    login: '',
    token: '',
    session: '',
    mode: '',
    group: '',
    loadingProfile: true,
    profile: {},
  },

  getters: {
    user(state) {
      if (state.loggedIn) {
        return {
          login: state.login,
          token: state.token,
          session: state.session,
          group: state.group,
        };
      }
      return null;
    },
    headerAuth(state) {
      if (state.loggedIn) {
        return {
          Authorization: `${state.login}:${state.token}:${state.session}`,
        };
      }
      return {};
    },
    isLoggedIn: (state) => state.loggedIn,
  },

  mutations: {
    loggedIn(state, prm) {
      state.loggedIn = true;
      storage.SET('r1_session', {
        login: prm.login,
        token: prm.token,
        mode: prm.mode,
        session: prm.session,
        group: prm.group,
      });
      state.login = prm.login;
      state.token = prm.token;
      state.mode = prm.mode;
      state.session = prm.session;
      state.group = prm.group;
    },
    loggedOut(state) {
      state.loggedIn = false;
      storage.DELETE('r1_session');
      state.login = '';
      state.token = '';
      state.mode = '';
      state.session = '';
      state.group = '';
    },
    UpdateToken(state, prm) {
      const ses = storage.GET('r1_session');
      storage.SET('r1_session', {
        login: ses.login,
        token: prm,
        mode: ses.mode,
        session: ses.session,
        group: ses.group,
      });
      state.token = prm;
    },
  },
};

export default authStore;
