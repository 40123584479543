/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const groupsStore = {
  namespaced: true,
  state: {
    groupList: [],
    totalGroups: 0,
  },

  mutations: {
    SET_GROUPS(state, payload) {
      state.groupList = payload.data;
      state.totalGroups = payload.total;
    },
    UPDATE_GROUP(state, payload) {
      state.groupList = state.groupList.map((group) => {
        // eslint-disable-next-line radix
        if (group.pg_id === parseInt(payload.pg_id)) {
          // eslint-disable-next-line no-param-reassign
          group = payload;
          return group;
        }
        return group;
      });
    },
  },
  actions: {
    async updateGroup(context, payload) {
      const { data } = await req.post('groupsSave', { obj: { ...payload } });
      if (data.code === 20) {
        context.commit('UPDATE_GROUP', payload);
      }
    },
    async getGroups(context, payload) {
      const response = await req.post('groups', payload);
      context.commit('SET_GROUPS', response.data);
    },
  },
};

export default groupsStore;
