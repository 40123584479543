/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const resourceTypesStore = {
  namespaced: true,
  state: {
    resourceTypesTotal: 0,
    resourceTypesList: [],
  },
  mutations: {
    SET_RESOURCE_TYPES(state, payload) {
      state.resourceTypesTotal = payload.total;
      state.resourceTypesList = payload.data;
    },
  },
  actions: {
    async getResourceTypes(context, payload) {
      const response = await req.post('meteringResourcesTypes', payload);
      context.commit('SET_RESOURCE_TYPES', response.data);
    },
  },
};

export default resourceTypesStore;
