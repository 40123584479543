import Vue from 'vue';
import VueMask from 'v-mask';
import VueToast from 'vue-toast-notification';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './registerServiceWorker';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './sclibFilters';
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-bootstrap.css'
import './assets/app.css';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.axios = axios;
Vue.prototype.ws = null;

Vue.use(VueMask);
Vue.use(VueToast, { duration: 5000 });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
