/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const accountsStore = {
  namespaced: true,
  state: {
    accountsList: [],
    accountsExternalsTypesList: [],
    totalAccounts: 0,
  },

  mutations: {
    SET_ACCOUNTS(state, payload) {
      state.accountsList = payload.data;
      state.totalAccounts = payload.total;
    },
    SET_ACCOUNTS_EXTERNALS_TYPES(state, payload) {
      state.accountsExternalsTypesList = payload.data;
    },
    DELETE_ACCOUNT(state, payload) {
      state.accountsList = state.accountsList.filter(
        (account) => account.a_id !== payload,
      );
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async addNewAccount(context, payload) {
      const { data } = await req.post('accountsSave', { obj: { ...payload } });
      if (data.code === 20) {
        return data;
      }
    },
    // eslint-disable-next-line consistent-return
    async updateAccount(context, payload) {
      const { data } = await req.post('accountsSave', { obj: { ...payload } });

      if (data.code === 20) {
        return data;
      }
    },
    async getAccounts(context, payload) {
      const { data } = await req.post('accounts', payload);
      context.commit('SET_ACCOUNTS', data);
    },
    // eslint-disable-next-line consistent-return
    async getAccountById(context, payload) {
      const { data } = await req.post('accountsGetItem', { id: payload });
      if (data.code === 20) {
        return data.data;
      }
    },
    // eslint-disable-next-line consistent-return
    async getAccountsExternals(context, payload) {
      const { data } = await req.post('accountsExternals', { id: payload });
      if (data.code === 20) {
        return data.data;
      }
    },
    removeAccount(context, payload) {
      context.commit('DELETE_ACCOUNT', payload);
    },
    async getAccountsExternalsTypes(context, payload) {
      const { data } = await req.post('accountsExternalsTypes', payload);

      context.commit('SET_ACCOUNTS_EXTERNALS_TYPES', data);
    },
    // eslint-disable-next-line consistent-return
    async addNewAccountExternals(context, payload) {
      const { data } = await req.post('accountsExternalsSave', { obj: { ...payload } });
      if (data.code === 20) {
        return data;
      }
    },
    async updateAccountExternals(context, payload) {
      await req.post('accountsExternalsSave', { obj: { ...payload } });
    },
    // eslint-disable-next-line consistent-return
    async removeAccountExternals(context, payload) {
      const { data } = await req.post('accountsExternalsDelete', payload);
      if (data.code === 20) {
        return data;
      }
    },
  },
};

export default accountsStore;
