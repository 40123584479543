export default {
  wrongLogin: 11,
  wrongPassword: 12,
  wrongLoginPasswordCode: 13,
  wrongTempCode: 14,
  wrongToken: 15,
  wrongRefreshToken: 16,
  blockedAccount: 17,
  temporaryBlockedAccount: 18,
  incorrectAccessRights: 19,
  ok: 20,
  wrongFormat: 31,
  emptyRequiredField: 32,
  encodeError: 33,
  decodeError: 34,
  redisError: 51,
  postgreError: 52,
  kafkaError: 53,
  kvrocksError: 54,
  mongoError: 55,
  mosquitoError: 56,
  cctvError: 57,
  skudError: 58,
  unknownError: 59,
  tokenUpdate: 66,
};
