/* eslint-disable import/prefer-default-export */
export const reportsRoutes = [
  {
    path: '/reports/report-one',
    name: 'ReportOne',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Отчеты', disabled: true },
        { text: 'Отчет №1', disabled: true },
      ],
    },
    component: () => import('@/components/Reports/ReportOne.vue'),
  },
];
