<template>
  <v-app id="r1-admin">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>
