/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const metersDataMapStore = {
  namespaced: true,
  state: {
    metersDataMapTotal: 0,
    metersDataMapList: [],
  },
  mutations: {
    SET_METERS_DATA_MAP(state, payload) {
      state.metersDataMapTotal = payload.total;
      state.metersDataMapList = payload.data;
    },
  },
  actions: {
    async getMetersDataMap(context, payload) {
      const response = await req.post('metersDataMap', payload);
      context.commit('SET_METERS_DATA_MAP', response.data);
    },
  },
};

export default metersDataMapStore;
