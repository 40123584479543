/* eslint-disable import/prefer-default-export */
export const clientsRoutes = [
  {
    path: '/clients/counterparties',
    name: 'Counterparties',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Клиенты', disabled: true },
        { text: 'Контрагенты', disabled: true },
      ],
    },
    component: () => import('@/components/Clients/Counterparties/CounterpartiesSubjects.vue'),
  },
  {
    path: '/clients/accounts/',
    name: 'Accounts',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Клиенты', disabled: true },
        { text: 'Лицевые счета', disabled: true },
      ],
    },
    component: () => import('@/components/Clients/Accounts/AccountsObjects.vue'),
  },
];
