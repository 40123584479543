import Vue from 'vue';

Vue.filter('fmtmoney', (value) => {
  if (!value) return '0,00';
  return (Math.round(value * 100) / 100).toFixed(2).replace(/\./g, ',');
});

Vue.filter('fmtmoneyspace', (value) => {
  if (!value) return '0,00';
  let val = (Math.round(value * 100) / 100).toFixed(2).replace(/\./g, ',');
  if (val.length < 7) return val;
  let spaced = val.slice(-6);
  val = val.slice(0, val.length - 6);
  while (val !== '') {
    if (val.length > 3) {
      spaced = `${val.slice(-3)} ${spaced}`;
      val = val.slice(0, val.length - 3);
    } else {
      spaced = `${val} ${spaced}`;
      val = '';
    }
  }
  return spaced;
});
