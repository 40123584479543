/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const appConfigStore = {
  namespaced: true,

  state: {
    appConfigObj: [],
  },

  mutations: {
    SET_APP_CONFIG_OBJ(state, payload) {
      state.appConfigObj = payload;
    },
  },

  actions: {
    async getAppConfigObj(context, payload) {
      const response = await req.post('appСonfig', payload);
      context.commit('SET_APP_CONFIG_OBJ', response.data.data);
    },
    async updateAppConfigObj(context, payload) {
      console.log('payload: ', JSON.stringify(payload, 0, 2));
      await req.post('appConfigSave', payload);
    },
  },
};

export default appConfigStore;
