/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const unitsStore = {
  namespaced: true,
  state: {
    unitsTotal: 0,
    unitsList: [],
  },
  mutations: {
    SET_UNITS(state, payload) {
      state.unitsTotal = payload.total;
      state.unitsList = payload.data;
    },
  },
  actions: {
    async getUnits(context, payload) {
      const response = await req.post('meteringDataUnits', payload);
      context.commit('SET_UNITS', response.data);
    },
    async createUnit(context, payload) {
      await req.post('meteringDataUnitsCreate', { obj: { ...payload } });
    },
    async updateUnit(context, payload) {
      await req.post('meteringDataUnitsUpdate', { obj: { ...payload } });
    },
  },
};

export default unitsStore;
