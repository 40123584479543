/* eslint-disable import/prefer-default-export */
export const billingRoutes = [
  {
    path: '/billing/schedule',
    name: 'Schedule',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Тарификация', disabled: true },
        { text: 'Расписание', disabled: true },
      ],
    },
    component: () => import('@/components/Billing/Shedule.vue'),
  },
  {
    path: '/billing/tariffs',
    name: 'Tariffs',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Тарификация', disabled: true },

        { text: 'Тарифы', disabled: true },
      ],
    },
    component: () => import('@/components/Billing/Tariffs.vue'),
  },
];
