/* eslint-disable import/prefer-default-export */
export const objectsRoutes = [
  {
    path: '/objects/cities/',
    name: 'Cities',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Объекты', disabled: true },
        { text: 'Города', disabled: true },
      ],
    },
    component: () => import('@/components/Objects/Cities/CitiesObjects.vue'),
  },
  {
    path: '/objects/houses/',
    name: 'Houses',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Объекты', disabled: true },
        { text: 'Дома', disabled: true },
      ],
    },
    component: () => import('@/components/Objects/Houses/HousesObjects.vue'),
  },
  {
    path: '/objects/premises/',
    name: 'Premises',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Объекты', disabled: true },
        { text: 'Помещения', disabled: true },
      ],
    },
    component: () => import('@/components/Objects/Premises/PremisesObjects.vue'),
  },
  {
    path: '/objects/metering-points/',
    name: 'MeteringPoints',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Объекты', disabled: true },
        { text: 'Точки учета', disabled: true },
      ],
    },
    component: () => import('@/components/Objects/MeteringPoints/MeteringPointsObjects.vue'),
  },
];
