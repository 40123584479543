/* eslint-disable import/prefer-default-export */
export const ticketsRoutes = [
  {
    path: '/tickets/actions/',
    name: 'Actions',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Действия', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/Actions/TicketsActions.vue'),
  },
  {
    path: '/tickets/agents/',
    name: 'Agents',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Исполнители', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/Agents/TicketsAgents.vue'),
  },
  {
    path: '/tickets/groups/',
    name: 'TicketsGroups',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Группы', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/Groups/TicketsGroups.vue'),
  },
  {
    path: '/tickets/objectsTypes/',
    name: 'TicketsObjects',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Типы объектов', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/ObjectsTypes/TicketObjectsTypes.vue'),
  },
  {
    path: '/tickets/priorities/',
    name: 'TicketsPriorities',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Приоритеты', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/Priorities/TicketsPriorities.vue'),
  },
  {
    path: '/tickets/states/',
    name: 'TicketsStates',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Статусы', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/States/TicketsStates.vue'),
  },
  {
    path: '/tickets/mapStates/',
    name: 'TicketStatesMap',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Заявки', disabled: true },
        { text: 'Статус - действия', disabled: true },
      ],
    },
    component: () => import('@/components/Tickets/StatesMap/TicketStatesMap.vue'),
  },
];
