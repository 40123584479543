<template>
  <component :is="layout"></component>
</template>

<script>
import { mapState } from 'vuex';
import LayoutLogin from './views/LayoutLogin.vue';
import LayoutMain from './views/LayoutMain.vue';

export default {
  name: 'App',

  watch: {
    error(val) {
      this.$toast.open({ message: val.msg, type: val.type });
    },
    notificationMessages(val) {
      this.$toast.open({ message: val.msg, type: val.type });
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.$store.commit("error", {
    //     type: "success",
    //     name: "LOGIN",
    //     msg: "Открыто приложение1",
    //   })
    // }, "10000")
    // setTimeout(() => {
    //   this.$store.commit("error", {
    //     type: "success",
    //     name: "LOGIN",
    //     msg: "Открыто приложение2",
    //   })
    // }, "2000")
    // setTimeout(() => {
    //   this.$store.commit("error", {
    //     type: "success",
    //     name: "LOGIN",
    //     msg: "Открыто приложение3",
    //   })
    // }, "3000")
  },
  computed: {
    layout() {
      return `Layout${this.$route.meta.layout || 'Login'}`;
    },
    ...mapState(['error', 'notificationMessages']),
  },
  components: {
    LayoutLogin,
    LayoutMain,
  },
};
</script>
