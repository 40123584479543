/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const controllersStore = {
  namespaced: true,
  state: {
    controllerModesTypesList: [],
    controllersList: [],
    totalControllers: 0,
  },

  mutations: {
    ADD_CONTROLLER(state, payload) {
      state.controllersList.push(payload);
    },
    SET_CONTROLLERS(state, payload) {
      state.controllersList = payload.data;
      state.totalControllers = payload.total;
    },
    SET_CONTROLLERS_MODES(state, payload) {
      state.controllerModesTypesList = payload.data;
    },
    UPDATE_CONTROLLER(state, payload) {
      state.controllersList = state.controllersList.map((contrl) => {
        if (contrl.c_id === payload.c_id) {
          // eslint-disable-next-line no-param-reassign
          contrl = payload;
          return contrl;
        }
        return contrl;
      });
    },
    DELETE_CONTROLLER(state, payload) {
      state.controllersList = state.controllersList.filter(
        // eslint-disable-next-line camelcase
        ({ c_id }) => c_id !== payload,
      );
    },
  },
  actions: {
    async addNewController(context, payload) {
      await req.post('controllersSave', {
        obj: { ...payload },
      });
    },
    async updateController(context, payload) {
      await req.post('controllersSave', {
        obj: { ...payload },
      });
    },
    async getControllers(context, payload) {
      const { data } = await req.post('controllers', payload);
      context.commit('SET_CONTROLLERS', data);
    },
    async getControllersModes(context, payload) {
      const { data } = await req.post('controllersModes', payload);
      context.commit('SET_CONTROLLERS_MODES', data);
    },
    async deleteController(context, payload) {
      context.commit('DELETE_CONTROLLER', payload);
    },
  },
};

export default controllersStore;
